export interface Range {
  id: string
  name: string
  external_reference: string
  bucket_configuration_id: string | null
  demand_model_id: string
}

export enum TrackingTech {
  TOPTRACER = 'toptracer',
  NONE = 'none',
}

export type BayType = {
  id: string
  bay_number: number
  drivingRangeUuid: string
  floor: number
  roof: boolean
  tracking_technology: TrackingTech
}

export enum RangeFilterType {
  TECHNOLOGIES = 'technologies',
  FLOORS = 'floors',
}

export interface RangeFilters {
  [RangeFilterType.TECHNOLOGIES]: {
    [key: string]: boolean
  }
  [RangeFilterType.FLOORS]: {
    [key: number]: boolean
  }
}

export type BayOption = {
  [key: string]: {
    name: string
    logo?: string
  }
}
